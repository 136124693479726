<template>
  <card body-classes="standard-card-body" class="shadow">
    <div slot="header">
      <div class="d-flex flex-column">
        <h4 class="card-title text-capitalize">{{ $t('auth.update_password') }}</h4>
        <div class="small">{{ $t('common.prevent_updating_if_blank') }}</div>
      </div>
    </div>
    <ValidationObserver ref="passwordForm">
      <ValidationProvider
        name="password"
        rules="required|min:5"
        vid="confirmation"
        v-slot="{ passed, failed, errors }"
      >
        <base-input
          required
          v-model="password"
          addon-left-icon="padlock"
          type="password"
          :error="errors[0]"
          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
        </base-input>
      </ValidationProvider>
      <ValidationProvider
        name="confirm_password"
        rules="required|confirmed:confirmation"
        v-slot="{ passed, failed, errors }"
      >
        <base-input
          required
          v-model="confirmPassword"
          addon-left-icon="padlock"
          type="password"
          :error="errors[0]"
          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
        </base-input>
      </ValidationProvider>
    </ValidationObserver>
  </card>
</template>

<script>
import {extend} from "vee-validate";
import {min, required, confirmed} from "vee-validate/dist/rules";

extend("min", min);
extend("required", required);
extend("confirmed", confirmed);

export default {
  name: "ProfilePasswordForm",
  data() {
    return {
      confirmPassword: '',
      password: '',
    }
  },
  methods: {
    updatePassword() {
      if (this.password === '' && this.confirmPassword === '') {
        return null
      }

      return new Promise((resolve, reject) => {
        this.$refs.passwordForm.validate()
          .then((success) => {
            if (!success) {
              reject();
            } else {
              this.$emit('onUpdatePassword', this.password);
              resolve();
            }
          })
          .catch((err) => {
            this.$notify({type: 'danger', message: this.$t('notifications.password_not_updated')});
            reject(err);
          });
      });
    },
  }
}
</script>

<style scoped>

</style>
