var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"shadow",attrs:{"body-classes":"standard-card-body"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"d-flex flex-column"},[_c('h4',{staticClass:"card-title text-capitalize"},[_vm._v(_vm._s(_vm.$t('auth.update_password')))]),_c('div',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t('common.prevent_updating_if_blank')))])])]),_c('ValidationObserver',{ref:"passwordForm"},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","addon-left-icon":"padlock","type":"password","error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"confirm_password","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","addon-left-icon":"padlock","type":"password","error":errors[0]},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }