<template>
  <div class="row">
    <div class="col-12">
      <octo-header-button :buttons="headerButtons" @onSaveUser="saveUser"/>
    </div>
    <div class="col col-md-7">
      <card footer-classes="text-right" class="shadow" body-classes="standard-card-body">
        <div slot="header"><h4 class="card-title text-capitalize">{{ $t('page.user_data') }}</h4></div>
        <user-form :user="user" ref="userForm" :key="`form-${userKey}`"/>
      </card>
      <octo-entity-addresses
        ref="octoEntityAddresses"
        :addresses="user.addresses"
        :key="`addresses-${addressesKey}`"
      />
      <octo-entity-emails
        ref="octoEntityEmails"
        :emails="user.emails"
        :key="`emails-${emailsKey}`"
      />
      <octo-entity-phones
        ref="octoEntityPhones"
        :phones="user.phones"
        :key="`phones-${phonesKey}`"
      />
    </div>
    <div class="col col-md-5">
      <profile-password-form ref="profilePasswordForm" @onUpdatePassword="updatePassword"/>
    </div>
  </div>

</template>

<script>
import UserForm from "@/pages/Users/forms/UserForm";
import {endpoints} from "@/routes/endpoints";
import User from "@/models/user";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import OctoEntityEmails from "@/components/octo-entity-emails/OctoEntityEmails";
import OctoEntityPhones from "@/components/octo-entity-phones/OctoEntityPhones";
import OctoEntityAddresses from "@/components/octo-entity-addresses/OctoEntityAddresses";
import ProfilePasswordForm from "@/pages/Users/forms/ProfilePasswordForm";

export default {
  name: "ProfilePage",
  components: {
    ProfilePasswordForm,
    OctoEntityAddresses,
    OctoEntityPhones,
    OctoEntityEmails,
    OctoHeaderButton,
    UserForm
  },
  data() {
    return {
      endpoints: endpoints,
      user: this.$_.cloneDeep(User),
      addressesKey: 0,
      emailsKey: 0,
      phonesKey: 0,
      userKey: 0,
      headerButtons: [{
        label: 'save',
        onClick: 'onSaveUser',
      }],
    }
  },
  beforeMount() {
    this.$fullLoading.show();
    this.$api.get(endpoints.USER_CURRENT)
      .then((resp) => {
        this.user = resp.data.data;

        this.addressesKey++;
        this.emailsKey++;
        this.phonesKey++;
        this.userKey++;

        this.$fullLoading.hide();
      })
      .catch(() => {
        this.$fullLoading.hide();
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        this.$router.push({name: 'users.index'});
      })
  },
  methods: {
    saveUser: async function () {
      try {
        this.$fullLoading.show();
        const userData = await this.$refs.userForm.getUserData();
        userData.emails = this.$refs.octoEntityEmails.getEntityEmails();
        userData.phones = this.$refs.octoEntityPhones.getEntityPhones();
        userData.addresses = this.$refs.octoEntityAddresses.getEntityAddresses();

        await this.$api({
          method: userData.id ? 'put' : 'post',
          url: userData.id
            ? endpoints.USER_UPDATE.replace('{id}', userData.id)
            : endpoints.USER_STORE,
          data: userData
        });

        await this.$refs.profilePasswordForm.updatePassword();

        this.userKey++;

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    updatePassword: function (password) {
      this.$api.post(endpoints.USER_UPDATE_PASSWORD, {
        password: password
      }).then(() => {
        this.$notify({type: 'success', message: this.$t('notifications.password_updated')});
      }).catch(() => {
        this.$notify({type: 'danger', message: this.$t('notifications.password_not_updated')});
      })
    }
  }
}
</script>

<style scoped>

</style>
